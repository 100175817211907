<template>
    <div>
        <v-banner two-line class="mt-12">
            <v-avatar
            slot="icon"
            color="deep-purple accent-4"
            size="40"
            >
            <v-icon
                icon="mdi-lock"
                color="white"
            >
                mdi-lock
            </v-icon>
            </v-avatar>

            <h3>Ruta no encontrada</h3>
            <h5>Esta página se redireccionara automaticamente en 10 segundos</h5>

            <template v-slot:actions>
            <v-btn
                text
                color="deep-purple accent-4"
                @click="$router.push('/')"
            >
                Ir a Inicio
            </v-btn>
            </template>
        </v-banner>
    </div>
</template>

<script>
export default {
    mounted(){
        setTimeout(() => { this.$router.push('/') }, 10000);
      },
}
</script>